// TODO: add additional rights
// FIXME:: there is no need for enum, use union type.
export enum ProfileLimitKey {
	TAGGING = 'tagging',
	AUTO_TAGGING = 'autoTagging',
	COMPANY_TEMPLATES = 'companyTemplates',
	DOC_X_REPORTS = 'docXReports',
	TASK_DASHBOARD = 'taskDashboard',
	TASK_TIMES_DASHBOARD = 'taskTimeDashboard',
	FILLABLE_FORMS = 'fillableForms',
	SIGNATURE_FORMS = 'signatureInForms',
	RECURRING_TASKS = 'recurringTasks',
	PROJECTS_FILTER = 'projectListFilters',
	GLOBAL_SEARCH = 'globalSearch',
	NOTIFICATION_CENTER = 'notificationCenter',
	NOTIFICATION_CHANNELS = 'notificationTimePlan',
	PDF_EDITING = 'pdfEditing',
	PDF_MEASURING = 'pdfMeasuring',
}

export enum ProfileLimitsRequire {
	ONE = 'ONE',
	ALL = 'ALL',
}
